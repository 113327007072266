<template>
  <div class="m-auto black">
    <div class="container m-auto">
      <div class="md:grid md:grid-cols-6 black relative">
        <div class="line"></div>
        <div class="line2"></div>
        <div class="line3"></div>
        <img class="light-shadow pt-4 logo-top m-auto" src="../assets/codeseed.svg" width="150px"/>
        <a href="/portfolio_codeseed.pdf" download><h2 class="link-top white-text">Portfolio</h2></a>
        <div class="md:col-span-3 white-text mt-auto pb-6">
        </div>
        <div class="md:col-span-3 white-text mt-auto pb-6">
        </div>
        <div class="md:col-span-4 white-text my-auto">
          <h1 class="light-shadow main-title font-barlow"><span id="spin"></span> APPS</h1>
        </div>
        <div class="md:col-span-2 white-text my-auto logo">
          <h3 class="subtitle font-barlow">WE ARE Digital Engineering Company</h3>
          <p>
            Team of experienced developers, designers and product managers. We build digital products for startups and enterprises. Our goal is to help you build the right product and bring it to market as fast as possible.
          </p>
          <!-- <img class="light-shadow" src="../assets/logo.svg" width="100%"/> -->
        </div>
        <div class="md:col-span-3 grow white-text mt-auto pb-6">
          <h1>OFFICE KOSICE</h1>
          <h3>Codeseed s.r.o.</h3>
          <h3>Macákova 10 040 11 Košice, Slovakia</h3>
          <h3>VAT: SK2122020362</h3>
        </div>
        <div class="col-span-3 grow white-text mt-auto pb-6">
          <h3>CONTACT</h3>
          <h3>+421 919 267 222</h3>
          <h3><a href="mailto:info@codeseed.sk">info@codeseed.sk</a></h3>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.line {
  width: 0.5px;
  height: 100vh;
  border: 1px solid #fff;
  left: 10%;
  transform: rotate(90);
  opacity: 0.2;
  position: absolute;
}
.line2 {
  width: 0.5px;
  height: 100vh;
  border: 1px solid #fff;
  right: 10%;
  transform: rotate(90);
  opacity: 0.2;
  position: absolute;
}
.line3 {
  width: 0.5px;
  height: 100vh;
  border: 1px solid #fff;
  right: 50%;
  transform: rotate(90);
  opacity: 0.2;
  position: absolute;
}
.black {
  background: #000;
  height: 100vh;
}
@media only screen and (max-width: 767px) {
  .black {
    height: auto;
    text-align: center;
  }
}
.white-text {
  color: #fff;
}
img.light-shadow{
  filter: drop-shadow(0px 0px 33px rgba(255, 255, 255, 0.7));
}
.light-shadow {
  filter: drop-shadow(0px 0px 33px rgba(255, 255, 255, 0.7));
}
.main-title {
  font-size: 120px;
  font-weight: 900;
  text-transform: uppercase;
}
.subtitle {
  text-transform: uppercase;
  font-size: 20px;
}
.logo {
  padding: 50px;
}
.relative {
  position: relative;
}
.logo-top {
  position: absolute;
  top: 0px;
  left: 0px;
}
.bottom-logos {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}
.link-top {
  position: absolute;
  top: 20px;
  right: 0px;
}
#spin:after {
  content:"";
  animation: spin 3s ease infinite;
}
@keyframes spin {
  0% { content:"build"; }
  10% { content:"build"; }
  20% { content:"build"; }
  30% { content:"test"; }
  40% { content:"release"; }
  50% { content: "monitor"; }
  60% { content: "deploy"; }
  70% { content: "operate"; }
  80% { content: "monitor"; }
  90% { content: "plan"; }
  100% { content:"code"; }
}
</style>
